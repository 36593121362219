*,
*::after,
*::before {
  box-sizing: border-box;
}

:root {
  font-size: 20px;
}

::selection {
  background: #2ddab8;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  /*background: #fff;*/
  overflow: hidden;
  color: #8b8b8b;
  font-size: 0.9rem;
}

html,
body {
  height: 100%;
  margin: 0;
}

a {
  color: #545454;
  text-decoration: none;
}

a:focus,
a:hover {
  color: #8b8b8b;
}

#root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.loading {
  padding: 10px;
  transform: translate3d(-50%, -50%, 0);
  color: #8b8b8b;
}

.tokyo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

.menuBg {
  position: relative;
  top: 0;
  left: 0;
  width: 20vw;
  height: 100vh;
  overflow: hidden;
  background-color: #ff0000;
  color: #fff;
}

@media screen and (min-width: 800px) {
  .menuBg,
  .menuItems,
  .logo {
    max-width: 250px;
  }
}

.menuItems {
  position: absolute;
  top: 20px;
  left: 0;
  width: 20vw;
  text-align: center;
}

.menuItems svg {
  max-width: 90%;
  padding: 10px 15px;
  cursor: pointer;
}

@media screen and (min-width: 500px) {
  .menuItems svg {
    max-width: 150px;
  }
}

.logo {
  position: fixed;
  bottom: 0vh;
  left: 0;
  width: 20vw;
  height: 60vh;
  text-align: center;
}

@media screen and (max-height: 500px) {
  .logo {
    display: none;
  }
}

.logo svg {
  width: auto;
  height: 95%;
  bottom: 20px;
  position: absolute;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.logo svg .st0 {
  fill: #ffffff;
}

.content {
  position: absolute;
  top: 0;
  right: 0;
  width: 80vw;
  height: 100vh;
  overflow: auto;
  z-index: 666;
}

@media screen and (min-width: 800px) {
  .content {
    width: calc(100vw - 250px);
  }
}

.scrollArea {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: auto;
}

.frame {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  padding: 1.5rem;
  z-index: 2;
}

.frame__title {
  font-size: inherit;
  font-weight: normal;
  margin: 0;
}

.frame__links {
  margin: 1rem 0;
}

.frame__link:not(:last-child) {
  margin-right: 1rem;
}

.frame__nav .frame__link:first-child {
  margin-right: 1.5rem;
}

.bottom-left {
  padding: 1.5rem;
  transform: translate3d(0, -100%, 0) !important;
}

canvas {
  height: 100%;
  margin: 0;
  padding: 0;
  width: 100%;
}

.canvas > div {
  z-index: 10000000;
}

@media screen and (min-width: 53em) {
  body {
    overflow: hidden;
  }
  .frame {
    display: grid;
    height: 100vh;
    padding: 2rem 3.7rem;
    pointer-events: none;
    align-content: space-between;
    grid-template-columns: 50% 50%;
    grid-template-rows: auto auto auto;
    grid-template-areas:
      "title links"
      "... ..."
      "... nav";
  }
  .frame__title {
    grid-area: title;
  }
  .frame__links {
    grid-area: links;
    justify-self: end;
    margin: 0;
  }
  .frame__link {
    pointer-events: auto;
  }
  .frame__nav {
    grid-area: nav;
    justify-self: end;
  }
  .bottom-left {
    padding: 0 0 2rem 3.7rem;
  }
}
